import styled from "styled-components";
import { space, color, layout, position, typography } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { EXCLUDED_CSS_PROP } from "../../const";

const Box = styled("div").withConfig({
  shouldForwardProp: prop => !EXCLUDED_CSS_PROP.includes(prop),
})(layout, space, color, position, typography);

Box.displayName = "Box";

Box.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.position,
  ...propTypes.typography,
};

export default Box;
