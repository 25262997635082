import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, Link as GatsbyLink, graphql } from "gatsby";
import { Link } from "../style/Link";

const LangSwitch = ({
  lang: currentLang,
  slug: currentSlug,
  onClick,
  color = "white",
}) => {
  const {
    slugs: { nodes },
  } = useStaticQuery(graphql`
    {
      slugs: allMarkdownRemark {
        nodes {
          frontmatter {
            slug
            slug_cz
          }
        }
      }
    }
  `);
  const otherLangSlug = nodes.reduce((acc, item) => {
    if (currentLang === "cz" && item.frontmatter.slug_cz === currentSlug) {
      acc = item.frontmatter.slug;
    }

    if (currentLang === "en" && item.frontmatter.slug === currentSlug) {
      acc = item.frontmatter.slug_cz;
    }
    return acc;
  }, "/");

  return (
    <Link as={GatsbyLink} color={color} to={otherLangSlug} onClick={onClick}>
      {currentLang === "en" ? "Česky" : "English"}
    </Link>
  );
};

LangSwitch.propTypes = {
  lang: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default LangSwitch;
