const LANGS = ["en", "cz"];

const parseHtmlContent = (html, lang) => {
  if (!html) {
    return "";
  }

  let currentLang = LANGS[0];
  let parsed = LANGS.reduce((acc, item) => {
    acc[item] = "";
    return acc;
  }, {});

  html.split(/<!-- ?lang:([a-z]{2}) -->/).forEach(item => {
    if (LANGS.includes(item)) {
      currentLang = item;
    } else {
      parsed[currentLang] += item;
    }
  });

  return parsed[lang];
};

const parseFrontmatter = (frontmatter, lang) =>
  Object.keys(frontmatter).reduce((acc, item) => {
    if (item.substr(-3, 3) === `_${lang}`) {
      acc[item.substr(0, item.length - 3)] = frontmatter[item];
    } else if (!acc[item]) {
      acc[item] = frontmatter[item];
    }
    return acc;
  }, {});

export const parseContent = (markdownNodeSrc, lang) => {
  const markdownNode = JSON.parse(JSON.stringify(markdownNodeSrc));

  if (lang === LANGS[0]) {
    return Object.assign(markdownNode, {
      html: parseHtmlContent(markdownNode.html, lang),
    });
  }

  return Object.assign(
    markdownNode,
    { html: parseHtmlContent(markdownNode.html, lang) },
    { frontmatter: parseFrontmatter(markdownNode.frontmatter, lang) }
  );
};
