import styled from "styled-components";
import {
  space,
  layout,
  border,
  position,
  color,
  boxShadow,
  flexbox,
} from "styled-system";
import propTypes from "@styled-system/prop-types";
import { EXCLUDED_CSS_PROP } from "../../const";

const Image = styled("img").withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !EXCLUDED_CSS_PROP.includes(prop),
})`
  ${space};
  ${layout};
  ${border};
  ${position};
  ${color};
  ${boxShadow};
  ${flexbox};
`;

Image.displayName = "Image";

Image.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.position,
  ...propTypes.color,
  ...propTypes.flexbox,
};

export { Image };
