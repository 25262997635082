import styled from "styled-components";
import Box from "../Grid/Box";
import { themeGet } from "@styled-system/theme-get";

const HeaderNav = styled(Box)`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    padding: 15px;
    color: ${themeGet("colors.lightTitaniumGrey")};
    &:hover {
      color: ${themeGet("colors.lightGreen")};
    }
  }
  a.active {
    color: ${themeGet("colors.lightGreen")};
  }
`;

HeaderNav.displayName = "HeaderNav";

HeaderNav.defaultProps = {
  as: "ul",
  display: "block",
  fontSize: "default",
  fontWeight: "regular",
  color: "lightTitaniumGrey",
};

export { HeaderNav };
