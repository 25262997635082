import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html, body {
        padding: 0;
        margin: 0;
        line-height: 1.5;
        font-family: 'Seravek', sans-serif;
        font-weight: 400;
        box-sizing: border-box;
    }
    
    *, *:before, *:after {
        box-sizing: inherit;
    }
    
    dl {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    
    a {
        text-decoration: none;
    }
    
    strong {
        font-weight: 500;
    }
`;

export default GlobalStyle;
