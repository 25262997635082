import styled from "styled-components";
import Box from "../Grid/Box";

const HeaderWrapper = styled(Box)`
  width: 100%;
  z-index: 1000;
  position: fixed;
  visibility: ${props => (props.isMenuTop ? "hidden" : "visible")};
  opacity: ${props => (props.isMenuTop ? "0" : "1")};
  transition: all 200ms linear;
`;

HeaderWrapper.displayName = "HeaderWrapper";

HeaderWrapper.defaultProps = {
  bg: "darkGreen",
};

export { HeaderWrapper };
