import styled from "styled-components";
import Box from "../Grid/Box";

const HeaderLogo = styled(Box)`
  a {
    display: block;
  }
`;

export { HeaderLogo };
