import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  position,
  border,
  flexbox,
} from "styled-system";
import propTypes from "@styled-system/prop-types";
import { EXCLUDED_CSS_PROP } from "../../const";

const Heading = styled.h1.withConfig({
  shouldForwardProp: prop => !EXCLUDED_CSS_PROP.includes(prop),
})`
  margin: 0;
  padding: 0;
  text-decoration: none;
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${position}
  ${border}
  ${flexbox}
`;

Heading.displayName = "Heading";

Heading.defaultProps = {
  fontSize: "default",
  fontWeight: "medium",
  lineHeight: 1,
  color: "darkGreen",
};

Heading.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
  ...propTypes.flexbox,
};

export { Heading };
