import styled from "styled-components";
import { Box } from "../Grid";

const MobileMenuToggle = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 55px;
  height: 55px;
  padding: 0;
  appearance: none;
  font-family: inherit;
  background-color: transparent;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
`;

export { MobileMenuToggle };
