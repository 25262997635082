const breakpoints = ["576px", "768px", "992px", "1200px"];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const colors = {
  black: "#000",
  white: "#FFF",
  cyberGreen: "#00E995",
  lightGreen: "#99CA45",
  darkGreen: "#003743",
  textGrey: "#3F595C",
  lightTitaniumGrey: "#CCD9DB",
  lightTitaniumGrey30: "#F0F4F4",
};

const sizes = {
  container: "1180px",
};

const space = [
  "0",
  "4px",
  "8px",
  "16px",
  "32px",
  "64px",
  "128px",
  "256px",
  "512px",
];

const fontSizes = {
  small: "13px",
  default: "16px",
  h1: "42px",
  h2: "32px",
  h3: "24px",
  h4: "20px",
};

const lineHeights = [1, 1.185, 1.25, 1.5, 2];

const fontWeights = {
  extraLight: 200,
  regular: 400,
  medium: 500,
};

export const theme = {
  name: "LeafClick",
  breakpoints,
  colors,
  sizes,
  space,
  fontSizes,
  lineHeights,
  fontWeights,
};
