import React, { useLayoutEffect, Fragment, useRef, useState } from "react";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Flex } from "../style/Grid";
import { Image } from "../style/Image";
import {
  MobileMenuToggle,
  MobileNavContent,
  HeaderWrapper,
  MobileNavMenu,
  NavLogo,
} from "../style/Navigation";
import { Text } from "../style/Text";
import { MobileNav } from "../style/Navigation/MobileNav";
import HeaderInner from "./HeaderInner";
import LangSwitch from "./LangSwitch";

let lastScrollTop = 0;

const Header = ({
  mobileMenuOpen,
  toggleMobileMenu,
  translations,
  links,
  lang,
  slug,
}) => {
  const navBarRef = useRef(null);
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [isMenuTop, setMenuTop] = useState(true);

  useLayoutEffect(() => {
    function handleScroll() {
      const delta = 5;
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (mobileMenuOpen) return;

      if (Math.abs(lastScrollTop - scrollTop) <= delta) return;

      setMenuTop(scrollTop <= 200);

      if (scrollTop > 0 && scrollTop > lastScrollTop) {
        setMenuVisible(false);
      } else {
        if (scrollTop + window.innerHeight < document.body.clientHeight) {
          setMenuVisible(true);
        }
      }

      lastScrollTop = scrollTop;
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Fragment>
      <HeaderWrapper
        ref={navBarRef}
        role="navigation"
        aria-label="main-navigation"
        isMenuVisible={isMenuVisible}
        isMenuTop={isMenuTop}
        top={[isMenuVisible ? "0" : "-85px"]}
      >
        <HeaderInner
          toggleMobileMenu={toggleMobileMenu}
          mobileMenuOpen={mobileMenuOpen}
          translations={translations}
          links={links}
          lang={lang}
          slug={slug}
        />
      </HeaderWrapper>

      <ScrollLock isActive={mobileMenuOpen} mobileMenuOpen={mobileMenuOpen} />
      {mobileMenuOpen && (
        <MobileNav>
          <TouchScrollable>
            <MobileNavContent>
              <Flex
                flexDirection="column"
                alignItems="center"
                height={"100%"}
                justifyContent="center"
              >
                <NavLogo>
                  <Link
                    to={links.hp}
                    onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                  >
                    <Image
                      display="block"
                      src={require("../assets/logo-mobile-menu.svg")}
                      alt="leafclick"
                    />
                  </Link>
                </NavLogo>

                <MobileMenuToggle
                  display="flex"
                  onClick={() => {
                    toggleMobileMenu(!mobileMenuOpen);
                  }}
                >
                  <Flex flexDirection="column" alignSelf="center" margin="auto">
                    <Image
                      src={require("../assets/images/menu-close.svg")}
                      alt={translations.close}
                    />
                    <Text as="p" color="darkGreen" mt={1} fontSize="small">
                      {translations.close}
                    </Text>
                  </Flex>
                </MobileMenuToggle>

                <MobileNavMenu>
                  <li>
                    <Link
                      to={links.hp}
                      onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                      activeClassName={"active"}
                    >
                      {translations.menuHome}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={links.about}
                      onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                      activeClassName={"active"}
                    >
                      {translations.menuAbout}
                    </Link>
                  </li>
                  <li
                    className={
                      document.location?.pathname.includes(
                        links.products.overview
                      ) && "submenu-active"
                    }
                  >
                    <Link
                      to={links.products.overview}
                      as="p"
                      onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                      className={
                        document.location?.pathname.includes(
                          links.products.overview
                        ) && "active"
                      }
                    >
                      {translations.menuProductsAndServices}
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to={links.products.cyberSecurity}
                          onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                          activeClassName={"active"}
                        >
                          {translations.menuCybersecurity}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={links.products.m2m}
                          onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                          activeClassName={"active"}
                        >
                          {translations.menuM2mCommunication}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={links.products.protectionOfSmartDevices}
                          onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                          activeClassName={"active"}
                        >
                          {translations.menuIot}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {false &&
		   <li>
                       <Link
			   to={links.glossary}
			   onClick={() => toggleMobileMenu(!mobileMenuOpen)}
			   activeClassName={"active"}
			   className={slug?.includes(links.glossary) ? "active" : ""}
                       >
                      {translations.menuGlossary}
			  </Link>
			  </li>
		  }
                  <li>
                    <Link
                      to={links.contact}
                      onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                      activeClassName={"active"}
                    >
                      {translations.menuContact}
                    </Link>
                  </li>
                  <li className={"lang"}>
                    <LangSwitch
                      onClick={() => toggleMobileMenu(!mobileMenuOpen)}
                      color={"inherit"}
                      slug={slug}
                      lang={lang}
                    />
                  </li>
                </MobileNavMenu>
              </Flex>
            </MobileNavContent>
          </TouchScrollable>
        </MobileNav>
      )}
    </Fragment>
  );
};

export default Header;

Header.propTypes = {
  mobileMenuOpen: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
};
