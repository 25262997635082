import styled from "styled-components";
import { color } from "styled-system";
import Box from "./Box";

const Wrapper = styled(Box)(color);

Wrapper.displayName = "Wrapper";

Wrapper.defaultProps = {
  width: "100%",
};

export default Wrapper;
