import styled from "styled-components";

import { Flex } from "../Grid";

const MobileNav = styled(Flex)`
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, white 0%, #ccd9db 70%);
`;

MobileNav.defaultProps = {};

export { MobileNav };
