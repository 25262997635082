import React from "react";
import { Box, Container, Flex } from "../style/Grid";
import { Text } from "../style/Text";
import { Heading } from "../style/Heading";
import { Image } from "../style/Image";
import { FooterStyle } from "../style/Footer";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "../style/Link";
import { parseContent } from "../utils/lang";

const Footer = ({ lang, translations }) => {
  const query = useStaticQuery(graphql`
    query FooterQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
        frontmatter {
          company
          ic
          legal
          legal_cz
          mail
        }
      }
    }
  `);

  const data = parseContent(query.markdownRemark, lang).frontmatter;

  return (
    <FooterStyle>
      <div className="bg" />
      <Container>
        <Flex
          width={[1]}
          height={[380]}
          py={4}
          flexDirection={["column"]}
          justifyContent={["space-between"]}
          zIndex={5}
          position="relative"
        >
          <Box>
            <Heading as="h3" color="lightTitaniumGrey" fontSize="h3" mb={3}>
              {translations.contactMoreInfo}
            </Heading>
            <Text color="lightTitaniumGrey">
              {translations.contactUsAt}{" "}
              <Link as="a" color="cyberGreen" href={`mailto:${data.mail}`}>
                {data.mail}
              </Link>
            </Text>
          </Box>

          <Flex
            alignItems={["flex-start", "center"]}
            flexDirection={["column", "row"]}
          >
            <Image
              src={require("../assets/logo-footer.svg")}
              width={35}
              height={35}
              mr={3}
              mb={3}
              alt={data.company}
            />

            <Text color="lightTitaniumGrey" lineHeight={1} fontSize="small">
              {data.company}
              <br/>
		{translations.contactCompanyId}&nbsp;{data.ic}
              <br/>
              {data.legal}
            </Text>
          </Flex>
        </Flex>
      </Container>
    </FooterStyle>
  );
};

export default Footer;
