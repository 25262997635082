import styled from "styled-components";
import { space, color, layout, typography, border } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { EXCLUDED_CSS_PROP } from "../../const";

const Text = styled.p.withConfig({
  shouldForwardProp: prop => !EXCLUDED_CSS_PROP.includes(prop),
})`
  margin: 0;
  padding: 0;
  ${space};
  ${color};
  ${layout};
  ${typography};
  ${border};
`;

Text.displayName = "Text";

Text.defaultProps = {
  color: "textGrey",
  fontSize: "default",
};

Text.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.border,
};

export { Text };
