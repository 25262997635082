import styled from "styled-components";
import Box from "../Grid/Box";

const NavLogo = styled(Box)`
  position: absolute;
  top: 16px;
  left: 15px;
  a {
    display: block;
  }
`;

export { NavLogo };
