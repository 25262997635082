import styled from "styled-components";
import { variant } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { Text } from "../Text";

const Link = styled(Text)(
  {
    cursor: "pointer",
    textDecoration: "none",
  },
  variant({
    variants: {
      regular: {
        "&, &:link, &:visited": {
          textDecoration: "none",
        },
        "&:hover, &:active, &:focus": {
          textDecoration: "underline",
        },
      },
      underline: {
        textDecoration: "underline",
        "&:hover, &:active, &:focus": {
          textDecoration: "none",
        },
      },
    },
  })
);

Link.displayName = "Link";

Link.defaultProps = {
  variant: "regular",
  display: "inline-block",
  fontSize: "default",
  fontWeight: "regular",
};

Link.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
};

export { Link };
