import styled from "styled-components";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

import Flex from "./Flex";

const Container = styled(Flex)(props => ({
  maxWidth: themeGet("sizes.container")(props),
  margin: "auto",
}));

Container.displayName = "Container";

Container.defaultProps = {
  flexDirection: "column",
  width: ["100%", null, null, null, "container"],
  px: ["15px", null, "31px", null, "15px"],
};

Container.propTypes = {
  ...propTypes.flexbox,
};

export default Container;
