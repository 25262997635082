import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const MobileNavMenu = styled("ul")`
  list-style: none;
  margin: 0;
  padding: 0;
  .submenu-active {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 30px;
      bottom: 10px;
      left: -20px;
      width: 1px;
      background: ${themeGet("colors.lightGreen")};
    }
    &:before {
      content: "";
      position: absolute;
      top: 22px;
      left: -24px;
      width: 10px;
      height: 10px;
      border-radius: 20px;
      background: ${themeGet("colors.lightGreen")};
    }
  }
  li a,
  li p {
    display: block;
    padding: 5px 0;
    font-size: ${themeGet("fontSizes.h3")};
    color: ${themeGet("colors.darkGreen")};
    &.active {
      color: ${themeGet("colors.lightGreen")};
    }
  }
  li.lang {
    position: absolute;
    bottom: 40px;
  }
  li.lang a {
    font-size: 18px;
    text-decoration: none;
  }
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .submenu {
    list-style: none;
    li a {
      display: block;
      padding: 3px 0;
      font-size: 18px;
    }
  }
`;

export { MobileNavMenu };
