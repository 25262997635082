import React from "react";

import { Box, Container, Flex } from "../style/Grid";
import { HeaderLogo, HeaderNav, MobileMenuToggle } from "../style/Navigation";
import { Link } from "gatsby";
import { Image } from "../style/Image";
import { Text } from "../style/Text";
import LangSwitch from "./LangSwitch";

const HeaderInner = ({
  toggleMobileMenu,
  mobileMenuOpen,
  translations,
  links,
  lang,
  slug,
}) => {
  return (
    <Container py={3} height={72}>
      <Flex width={1} alignItems="center" justifyContent="space-between">
        <HeaderLogo>
          <Link to={links.hp}>
            <Image
              display="block"
              src={require("../assets/logo.svg")}
              alt="leafclick"
            />
          </Link>
        </HeaderLogo>

        <Flex display={["none", null, "flex"]}>
          <HeaderNav>
            <li>
              <Link to={links.hp} activeClassName={"active"}>
                {translations.menuHome}
              </Link>
            </li>
            <li>
              <Link to={links.about} activeClassName={"active"}>
                {translations.menuAbout}
              </Link>
            </li>
            <li>
              <Link to={links.products.overview} activeClassName={"active"}>
                {translations.menuProductsAndServices}
              </Link>
            </li>
              {false &&
		  <li>
		      <Link
			  to={links.glossary}
			  activeClassName={"active"}
			  className={slug?.includes(links.glossary) ? "active" : ""}
		      >
			  {translations.menuGlossary}
		      </Link>
		  </li>}
            <li>
              <Link to={links.contact} activeClassName={"active"}>
                {translations.menuContact}
              </Link>
            </li>
          </HeaderNav>
        </Flex>
        <Box display={["none", null, "inline"]}>
          <LangSwitch lang={lang} slug={slug} />
        </Box>

        <MobileMenuToggle
          display={["flex", null, "none"]}
          onClick={() => {
            toggleMobileMenu(!mobileMenuOpen);
          }}
        >
          <Flex flexDirection="column" alignSelf="center" margin="auto">
            <Image
              src={require("../assets/images/menu-open.svg")}
              alt={translations.open}
            />
            <Text as="p" color="white" fontSize="small">
              Menu
            </Text>
          </Flex>
        </MobileMenuToggle>
      </Flex>
    </Container>
  );
};

export default HeaderInner;
