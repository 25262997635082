import styled from "styled-components";

const MobileNavContent = styled("div")`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
`;

export { MobileNavContent };
