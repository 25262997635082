import React, { useState } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import Header from "../components/Header";
import Footer from "../components/Footer";
import GlobalStyle from "../style/GlobalStyles";
import { theme } from "../style/theme";
import { links as csLinks } from "../const/index";

const Layout = ({
  children,
  pageContext: { translations = {}, links = csLinks.cz, lang, slug },
}) => {
  const [mobileMenuOpen, toggleMobileMenu] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet>
        <meta name="format-detection" content="telephone=no" />
        <html lang="en" />
        <title>{translations.metaDefaultTitle}</title>
        <meta
          name="description"
          content={translations.metaDefaultDescription}
        />
        <meta property="og:title" content={translations.metaDefaultTitle} />
        <meta
          property="og:description"
          content={translations.metaDefaultDescription}
        />
        <meta property="og:url" content={`https://leafclick.com${slug}`} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={"/favicon/apple-touch-icon.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" crossorigin="use-credentials" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#00e995" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:type" content="business.business" />
      </Helmet>

      <Header
        mobileMenuOpen={mobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
        translations={translations}
        links={links}
        lang={lang}
        slug={slug}
      />

      {React.Children.map(children, child =>
        React.cloneElement(child, {
          mobileMenuOpen,
          toggleMobileMenu,
        })
      )}

      <Footer lang={lang} translations={translations} />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
