const contact = {
  mail: "info@leafclick.com",
};

const links = {
  cz: {
    hp: "/cz",
    about: "/cz/o-nas",
    glossary: "/cz/slovnik-pojmu",
    products: {
      overview: "/cz/produkty-a-sluzby",
      cyberSecurity: "/cz/produkty-a-sluzby/kyberneticka-bezpecnost",
      m2m: "/cz/produkty-a-sluzby/bezpecna-m2m-komunikace",
      protectionOfSmartDevices: "/cz/produkty-a-sluzby/iot-firmware",
    },
    contact: "/cz/kontakt",
  },
  en: {
    hp: "/",
    about: "/about-us",
    glossary: "/glossary",
    products: {
      overview: "/products-and-services",
      cyberSecurity: "/products-and-services/cybersecurity",
      m2m: "/products-and-services/secure-m2m-communication",
      protectionOfSmartDevices: "/products-and-services/iot-firmware",
    },
    contact: "/contact",
  },
};

const EXCLUDED_CSS_PROP = [
  "fontSize",
  "fontWeight",
  "lineHeight",
  "color",
  "padding",
  "p",
  "px",
  "py",
  "pt",
  "pl",
  "pb",
  "pr",
  "margin",
  "m",
  "mx",
  "my",
  "mt",
  "ml",
  "mb",
  "mr",
  "textAlign",
  "width",
  "height",
  "maxWidth",
  "isMenuVisible",
  "isMenuTop",
  "maxHeight",
  "maxWidth",
  "minHeight",
  "display",
  "flexDirection",
  "alignSelf",
  "alignItems",
  "justifyContent",
  "flexWrap",
  "zIndex",
  "top",
  "left",
  "right",
  "bottom",
  "position",
  "bg",
  "variant",
];

module.exports = { contact, links, EXCLUDED_CSS_PROP };
